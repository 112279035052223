export default [
	// 'mock',
	// 'consult',
	'report_submit',

	'quiz-view',
	'quiz-steps',
	'quiz-loading',
	'quiz-question',
	'quiz-question-tags',
	'quiz-question-select',
	'quiz-redirecting-report',

	'fake-question',

	// 'sample-quiz-view',
	// 'sample-quiz-steps',
	// 'sample-quiz-question',
	// 'sample-quiz-report',
]
