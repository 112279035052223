<template ref="main">
	<nuxt/>
</template>

<script>
	import ResizeObserver from 'resize-observer-polyfill';
	import BaseLayout from './base'
	import components from './tt-readiness/components'

	export default {
		extends: BaseLayout,

		components: {},

		computed: {},

		data() {
			return {
				components,
				recaptcha: null,
				is_fullscreen: false,
				viewport_break_points: [
					{name: 'xxs', max_width: 524},
					{name: 'xs', max_width: 768},
					{name: 'sm', max_width: 992},
					{name: 'md', max_width: 1600},
					{name: 'lg', max_width: 2000},
					{name: 'xl', max_width: 99999},
				],
			}
		},

		created() {
			if (process.client) {
			}
		},
		mounted() {
			if (process.client) {
				this.ro = new ResizeObserver(([entry], observer) => {
					this.postHeightEvent(entry.contentRect.height);
				});

				this.$watch('is_fullscreen', this.processIsFullscreen.bind(this), {immediate: true})
			}
		},

		methods: {
			postHeightEvent(entry_height) {
				entry_height = entry_height || document.body.children[0].getBoundingClientRect().height;
				this.postMessageToOrigin({
					kind: 'iframe_height',
					is_fullscreen: this.is_fullscreen,
					height: entry_height,
					iframe_height: this.is_fullscreen ? 'fullscreen' : entry_height // Legacy support
				});
			},

			processIsFullscreen() {
				if (this.is_fullscreen) {
					this.ro.unobserve(document.body.children[0]);
				}
				if (!this.is_fullscreen) {
					this.ro.observe(document.body.children[0]);
				}
				this.postHeightEvent();
			}
		},

		head() {
			let ret = {
				title: this.$store.state.site.article && this.$store.state.site.article.head.title || 'Remote readiness quiz',
				meta: [],
				link: [
					{rel: 'icon', type: 'image/x-icon', href: this.$store.state.site.icon_file.filepath},
					// { rel: 'manifest', href: '/manifest.webmanifest' }
				],
			};
			this.enrichHead(ret);
			return ret;
		},
	}
</script>

<style lang="scss" type="text/scss">
	@import "~/assets/styles/tt-readiness/global.scss";
</style>

<style lang="scss" type="text/scss" module="global_style">
	@import "~/assets/styles/tt-readiness/global.scss";
</style>
