import componentIndex from './index';
import component404 from './404';
import componentQuestionnaireStart from './questionnaire/start';
import componentQuestionnaireStep from './questionnaire/step';
import componentQuestionnaireReport from './questionnaire/report';
import blocks_names_list from "./blocks/names_list";
import getPubBlocksMap from '../get_pub_blocks_map';

let components = {
  componentIndex,
  component404,
  componentQuestionnaireStart,
  componentQuestionnaireStep,
  componentQuestionnaireReport,
};

components.blocks = getPubBlocksMap('tt-readiness', blocks_names_list);
// for (let block_name of blocks_names_list) {
//   components.blocks[block_name] = () => {
// 	  let blocks_path = './blocks';
// 	  if (block_name.indexOf('common-') === 0) {
// 		  blocks_path = '@/layouts/common-blocks';
// 	  }
//     return import(blocks_path + '/' + block_name + '/pub');
//   }
// }

export default components
