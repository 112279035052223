<template>
	<div :class="m.logos_line">
		<div :class="m.logos_line__container">
			<div :class="m.logos_line__intro">Trusted by Leading brands</div>
			<div :class="m.logos_line__logo">
				<img src="/tt-readiness/svg/logo_costar.svg"/>
			</div>
			<div :class="m.logos_line__logo">
				<img src="/tt-readiness/svg/logo_bridgestone.svg"/>
			</div>
			<div :class="m.logos_line__logo">
				<img src="/tt-readiness/svg/logo_motorola.svg"/>
			</div>
			<div :class="m.logos_line__logo">
				<img src="/tt-readiness/svg/logo_usc.svg"/>
			</div>
			<div :class="m.logos_line__logo">
				<img src="/tt-readiness/svg/logo_csr.svg"/>
			</div>
			<div :class="m.logos_line__logo">
				<img src="/tt-readiness/svg/logo_cavaliers.svg"/>
			</div>
		</div>
	</div>
</template>

<script>
	export default {}
</script>

<style lang="scss" type="text/scss" module="m">
	@import "~/assets/styles/tt-readiness/defs.scss";

	.logos_line {
		padding: 28px 0 0;
		background: $clr-gray-white;
		width: 100%;

		&__container {
			@include container-lg();

			display: flex;
			flex-direction: row;
			justify-content: center;
			align-items: center;
			flex-wrap: wrap;
		}

		&__logo {
			flex-shrink: 0;
			margin: 0 25px 22px;
			position: relative;

			&_label {
				font-size: $font-size-9;
				line-height: 1.2em;
				color: $clr-gray-dark;
				position: absolute;
				top: calc(100% - 2px);
			}
		}

		&__intro {
			margin: 0 25px 22px;
			font-size: $font-size-12;
			letter-spacing: 0.1em;
			text-transform: uppercase;
			color: $clr-gray-blue;

			@include media-max-specific(1140px) {
				width: 100%;
				text-align: center;

				br {
					display: none;
				}
			}
		}
	}


</style>
