<template>
	<div :class="m.consult">
		<div :class="m.consult__h">Toptal Remote Work Consulting</div>
		<div :class="m.consult__sub">Each consulting engagement is custom to your organization’s needs and can
			include a variety of options.
		</div>
		<div :class="m.consult__content">
			<div :class="m.consult__section">
				<div :class="m.consult__section_h">
					People + Operations
				</div>
				<div :class="m.consult__section_icons_line">
					<div :class="m.consult__section_icon"
						 :style="{ backgroundImage: 'url(/tt-readiness/svg/icon_people.svg)' }"></div>
					<div :class="m.consult__section_icon"
						 :style="{ backgroundImage: 'url(/tt-readiness/svg/icon_cal.svg)' }"></div>
					<div :class="m.consult__section_icon"
						 :style="{ backgroundImage: 'url(/tt-readiness/svg/icon_time.svg)' }"></div>
					<div :class="m.consult__section_icon"
						 :style="{ backgroundImage: 'url(/tt-readiness/svg/icon_percent.svg)' }"></div>
					<div :class="m.consult__section_icon"
						 :style="{ backgroundImage: 'url(/tt-readiness/svg/icon_upload.svg)' }"></div>
					<div :class="m.consult__section_icon"
						 :style="{ backgroundImage: 'url(/tt-readiness/svg/icon_paper.svg)' }"></div>
				</div>
				<div :class="m.consult__section_desc">
					Discover the tactics and best practices necessary to lead a thriving remote team or
					enterprise.
				</div>
				<div :class="m.consult__section_list">
					<div :class="m.consult__section_item">
						<ProfitIcon :class="m.consult__section_img"></ProfitIcon>
						Managing Productivity
					</div>
					<div :class="m.consult__section_item">
						<SightIcon :class="m.consult__section_img"></SightIcon>
						Communication Cadence
					</div>
					<div :class="m.consult__section_item">
						<PeopleSearchIcon :class="m.consult__section_img"></PeopleSearchIcon>
						Employee Engagement
					</div>
					<div :class="m.consult__section_item">
						<StrategyIcon :class="m.consult__section_img"></StrategyIcon>
						Resource Management
					</div>
					<div :class="m.consult__section_item">
						<ManStudyIcon :class="m.consult__section_img"></ManStudyIcon>
						Management Training
					</div>
					<div :class="m.consult__section_item">
						<SkillsIcon :class="m.consult__section_img"></SkillsIcon>
						Remote Working Best Practices
					</div>
				</div>
				<!--<div :class="m.consult__section_ps">-->
				<!--** Additional consultation and execution support is available-->
				<!--</div>-->
			</div>
			<div :class="m.consult__section_between">
				<div :class="m.consult__section_h">
					&nbsp;
				</div>
				<div :class="m.consult__section_icons_line">
					<div ref="agreement_icon"
						 :class="[m.consult__section_agreement_icon, !is_agreement_icon_shown && m.consult__section_agreement_icon___hidden]"></div>
				</div>
			</div>
			<div :class="m.consult__section">
				<div :class="m.consult__section_h">
					Technology + Tools
				</div>
				<div :class="m.consult__section_icons_line">
					<img :class="m.consult__section_icon_logo" src="/tt-readiness/svg/icon_logo_zoom.svg"/>
					<img :class="m.consult__section_icon_logo" src="/tt-readiness/svg/icon_logo_slack.svg"/>
					<img :class="m.consult__section_icon_logo" src="/tt-readiness/svg/icon_logo_jira.svg"/>
					<img :class="m.consult__section_icon_logo" src="/tt-readiness/svg/icon_logo_trello.svg"/>
					<img :class="m.consult__section_icon_logo" src="/tt-readiness/svg/icon_logo_gdrive.svg"/>
					<img :class="m.consult__section_icon_logo" src="/tt-readiness/svg/icon_logo_lock.svg"/>
				</div>
				<div :class="m.consult__section_desc">
					Employ the right technology necessary to support a global remote organizational
					infrastructure.
				</div>
				<div :class="m.consult__section_list">
					<div :class="m.consult__section_item">
						<CodeIcon :class="m.consult__section_img"></CodeIcon>
						Remote Access Capabilities
					</div>
					<div :class="m.consult__section_item">
						<StrategyIcon :class="m.consult__section_img"></StrategyIcon>
						Virtual & Cloud Infrastructure
					</div>
					<div :class="m.consult__section_item">
						<ProfitIcon :class="m.consult__section_img"></ProfitIcon>
						Remote Tech Project Management Process
					</div>
					<div :class="m.consult__section_item">
						<ThirdPartyIcon :class="m.consult__section_img"></ThirdPartyIcon>
						IT Solutions
					</div>
					<div :class="m.consult__section_item">
						<SkillsIcon :class="m.consult__section_img"></SkillsIcon>
						Security & Access Control
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>

	import CodeIcon from '../../svg/code_icon.svg';
	import ManStudyIcon from '../../svg/man_study.svg';
	import PeopleSearchIcon from '../../svg/people_search_icon.svg';
	import ProfitIcon from '../../svg/profit_icon.svg';
	import SearchDocIcon from '../../svg/search_doc_icon.svg';
	import SightIcon from '../../svg/sight_icon.svg';
	import SkillsIcon from '../../svg/skills_icon.svg';
	import SpeedIcon from '../../svg/speed_icon.svg';
	import StrategyIcon from '../../svg/strategy_icon.svg';
	import TechsIcon from '../../svg/techs_icon.svg';
	import ThirdPartyIcon from '../../svg/third_party_icon.svg';

	export default {
		props: {
			value: {
				type: Object,
				required: true
			},
			global_style: {
				required: true
			}
		},

		data: () => ({
			is_agreement_icon_shown: true
		}),
		components: {
			CodeIcon,
			ManStudyIcon,
			PeopleSearchIcon,
			ProfitIcon,
			SearchDocIcon,
			SightIcon,
			SkillsIcon,
			SpeedIcon,
			StrategyIcon,
			TechsIcon,
			ThirdPartyIcon
		},
	}
</script>

<style lang="scss" module='m'>
	@import "~/assets/styles/tt-readiness/defs.scss";

	.consult {
		@include container_lg();

		&__h {
			font-size: $font-size-28;
			margin-bottom: 16px;
			text-align: center;

			@include media-min('md') {
				font-size: $font-size-44;
			}
		}

		&__sub {
			font-size: $font-size-20;
			font-weight: 300;
			margin-bottom: 40px;
			text-align: center;
		}

		&__content {
			position: relative;

			@include media-min('xs') {
				display: flex;
				flex-direction: row;
				align-items: flex-start;
				justify-content: space-between;
			}
		}

		&__section_between {
			display: none;

			@include media-min('sm') {
				display: flex;
				flex-direction: column;
				align-items: center;
				margin: 0 24px;
			}

			@include media-min('sm') {
				margin: 0 32px;
			}
		}

		&__section {
			&:first-child {
				margin-bottom: 80px;
			}

			@include media-min('xs') {
				width: calc(50% - 24px);

				&:first-child {
					margin-right: 48px;
				}
			}

			@include media-min('xs') {
				&:first-child {
					margin-bottom: 0;
				}
			}

			@include media-min('sm') {
				display: flex;
				flex-direction: column;
				align-items: center;
				flex: 0 1 50%;
				max-width: 390px;

				&:first-child {
					margin-right: 0;
				}
			}

			@include media-min('lg') {
			}

			&_h {
				font-weight: 600;
				margin-bottom: 32px;
				font-size: $font-size-20;

				@include media-min('sm') {
					font-size: $font-size-28;
				}
			}

			&_icons_line {
				margin-bottom: 32px;
				display: flex;
				justify-content: space-between;
				width: 100%;
				max-width: 346px;

				> * {
					@include media-max('xxs') {
					}
				}

				@include media-min('sm') {
					justify-content: space-around;
					max-width: none;
				}
			}

			&_icon {
				flex-shrink: 0;
				border-radius: 50%;
				background-size: 50%;
				background-repeat: no-repeat;
				background-position: center;
				width: 32px;
				height: 32px;

				@include media-min-specific(350px) {
					width: 48px;
					height: 48px;
				}

				@include media-between('xs', 'sm') {
					width: 32px;
					height: 32px;
				}

				&:nth-child(1) {
					background-color: #05947c;
				}

				&:nth-child(2) {
					background-color: #03b080;
				}

				&:nth-child(3) {
					background-color: #25a9ef;
				}

				&:nth-child(4) {
					background-color: #204ecf;
				}

				&:nth-child(5) {
					background-color: #183a9e;
				}

				&:nth-child(6) {
					background-color: #0f256e;
				}
			}

			&_icon_logo {
				width: 32px;
				height: 32px;

				@include media-min-specific(350px) {
					width: 48px;
					height: 48px;
				}

				@include media-between('xs', 'sm') {
					width: 32px;
					height: 32px;
				}
			}

			&_agreement_icon {
				margin-top: -8px;
				border-radius: 50%;
				background-size: 50%;
				background-color: $clr-black;
				background-repeat: no-repeat;
				background-position: center;
				background-image: url(/tt-readiness/svg/icon_agreement.svg);
				border: 8px solid $clr-gray-darky;
				width: 48px;
				height: 48px;

				@include media-min('md') {
					margin-top: -10px;
					border: 10px solid $clr-gray;
					width: 68px;
					height: 68px;
				}

				&::after {
					position: absolute;
					content: '';
					left: 20px;
					right: 20px;
					margin-top: 16px;
					border-top: dashed 1px $clr-gray-dark;
					z-index: -1;

					@include media-min('md') {
						margin-top: 24px;
					}
				}

				&___hidden {
				}
			}

			&_desc {
				color: $clr-gray-blue;
				padding-bottom: 23px;
				border-bottom: 1px solid $clr-gray-dark;
				margin-bottom: 23px;

				@include media-min('sm') {
					border-bottom: none;
					padding-bottom: 0;
					margin-bottom: 28px;
					text-align: center;
				}
			}

			&_list {

			}

			&_item {
				margin-left: 32px;
				padding-bottom: 23px;
				margin-bottom: 23px;
				border-bottom: 1px solid $clr-gray-dark-light;
				position: relative;
				color: $clr-gray-blue;

				&:last-child {
					padding-bottom: 10px;
					border-bottom: none;
					margin-bottom: 0;
				}
			}

			&_img {
				position: absolute;
				top: 0;
				left: -32px;
				width: 24px;
				height: 24px;
			}

			&_ps {
				font-size: $font-size-12;
			}
		}
	}

</style>
