exports = module.exports = require("../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".container_3u0K{text-align:center;font-size:24px;padding:15vh 0 20vh}.error-page__svg_2tj_{max-width:605px;margin:30px auto}.error-page_3T0B h1{font-size:32px;font-weight:900}.error-page__under_1Ziw{padding-top:68px;margin-top:78px;border-top:1px solid #4ec08d;display:flex;flex-direction:column}@media (min-width:768px){.error-page__under_1Ziw{flex-direction:row;justify-content:space-between}}.error-page__under-col_2W9l{text-align:left}.error-page__under-col_2W9l h2{font-size:24px;font-weight:900;margin-top:0}.error-page__under-col_2W9l p{font-size:20px}@media (min-width:768px){.error-page__under-col_2W9l{flex:0 0 28%}}", ""]);

// exports
exports.locals = {
	"container": "container_3u0K",
	"error-page__svg": "error-page__svg_2tj_",
	"error-page": "error-page_3T0B",
	"error-page__under": "error-page__under_1Ziw",
	"error-page__under-col": "error-page__under-col_2W9l"
};