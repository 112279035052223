<template>


	<div :class="m.frame">

		<div :class="m.header">
			<div :class="m.header__container">
				<LogoSvg :class="m.header__logo_m"></LogoSvg>
				<LogoFullColoredSvg :class="m.header__logo"></LogoFullColoredSvg>
				<div :class="m.header__sep"></div>
				<div :class="m.header__descriptor">Remote Readiness Assessment</div>
			</div>
		</div>

		<div :class="m.frame__container">
			<div></div>
			<transition name="fade">
				<div :class="m.step" v-if="$store.state.questionnaire && $store.state.questionnaire.question">
					<div :class="m.step__question">
						<div :class="m.step__question_title"
							 v-html="processHTML($store.state.questionnaire.question.question)">
						</div>
						<div :class="m.step__question_desc" v-if="$store.state.questionnaire.question.tip">
							{{$store.state.questionnaire.question.tip}}
						</div>
					</div>

					<div
							:class="[m.step__variants, $store.state.questionnaire.question.answer_variants.length >= 5 && m.step__variants___two_cols]">

						<div
								:class="[
											m.step__variant,
											current_selection === answer_variant_i && m.step__variant___selected
										]"
								@click="selectVariant(answer_variant_i)"
								v-for="(answer_variant, answer_variant_i) of $store.state.questionnaire.question.answer_variants"
								:key="'answer-' + $store.state.questionnaire.question.question.key + '-' + answer_variant_i"
						>
							<div :class="m.step__variant_pic" v-html="answer_variant.svg"></div>
							<div :class="m.step__variant_texts">
								{{ answer_variant.title }}
								<div v-if="answer_variant.attributes_values.description" :class="m.step__variant_desc">
									({{ answer_variant.attributes_values.description }})
								</div>
							</div>
						</div>
					</div>
				</div>
			</transition>
			<transition name="fade">
				<div
						:class="m.nav" ref="navbar"
						v-if="$store.state.questionnaire && $store.state.questionnaire.question">

					<div :class="m.nav__container">
						<div :class="m.nav__progress">
							<div
									:class="m.nav__progress_fill"
									:style="{width: $store.state.site.questionnaire_step_sn/questions_count*100+'%'}"></div>
						</div>
						<div :class="m.nav__side">
							<div
									:class="[m.nav__back, $store.state.site.questionnaire_step_sn > 0 && m.nav__back___visible]"
									@click="goBack">
								<ArrowSvg></ArrowSvg>
								Previous Question
							</div>
						</div>
						<div :class="m.nav__pagination">
							{{$store.state.site.questionnaire_step_sn+1}}/{{ questions_count }}
						</div>
					</div>
				</div>
			</transition>
			<div></div>
			<div></div>
			<div></div>
		</div>
		<!--<transition name="fade">-->

		<!--<TrustLine v-if="$store.state.questionnaire && $store.state.questionnaire.question"></TrustLine>-->
		<!--</transition>-->
	</div>
</template>

<script>
	import numeral from 'numeral';
	import {Mutex} from 'await-semaphore';
	import LogoSvg from '../svg/toptal_logo.svg';
	import LogoFullColoredSvg from '../svg/toptal_logo_full_color.svg';
	import ArrowSvg from '../svg/back_arrow.svg';
	import TrustLine from '../components/trust_line';

	function wait(ms) {
		return new Promise((resolve, reject) => {
			setTimeout(resolve, ms);
		})
	}

	export default {

		props: {
			global_style: {
				required: true
			}
		},

		computed: {

			curQuestion() {
				return this.$store.state.questionnaire.question;
			},

			isGoodSelection() {
				return this.curQuestion &&
						(this.curQuestion.is_multiselect && this.current_selection && this.curQuestion.multiselect_required_count <= this.current_selection.length
								|| !this.curQuestion.is_multiselect && typeof this.current_selection !== 'undefined' && this.current_selection !== null);
			},

			questions_count() {
				if (this.$store.state.questionnaire && this.$store.state.questionnaire.answers_list && this.$store.state.questionnaire.answers_list.questions) {
					return this.$store.state.questionnaire.answers_list.questions.length;
				}
				return 0;
			}
		},
		data() {
			return {
				score: 0,
				current_selection: [],
				is_current_selection_saved: false,
				mutex: new Mutex(),
				is_navbar_fixed: false,
				navbar_top: 0,
			}
		},
		async created() {
			this.$root.$children[1].is_fullscreen = true;
			await this.$watch('$store.state.site.questionnaire_step_sn', async (step_sn, prev_step_sn) => {
				if (step_sn === -1) {
					return false;
				}
				if (process.client) {
					await this.$store.state.graph.session_init_promise;
				}
				let releaseMutex = await this.mutex.acquire();
				await (this.$store.dispatch('questionnaire/loadQuestion', this.$store.state.questionnaire.answers_list.questions[step_sn])); // TODO: catch out of array
				releaseMutex();

				if (process.client) {
					this.$store.dispatch('questionnaire/setProgress',
							this.$store.state.site.questionnaire_step_sn / this.$store.state.questionnaire.answers_list.questions.length);
					await this.$nextTick();
					this.onScroll();
				}

				this.current_selection = this.$store.state.questionnaire.answers_list.chosen_variants[this.$store.state.questionnaire.question.key];
				if (this.current_selection === undefined && this.$store.state.questionnaire.question.is_multiselect) {
					this.current_selection = [];
				}

				this.is_current_selection_saved = false;
			}, {
				immediate: true
			});
		},
		components: {
			LogoSvg,
			LogoFullColoredSvg,
			ArrowSvg,
			TrustLine,
		},

		mounted() {
			if (process.client) {
				this.onScrollHandle = this.onScroll.bind(this);

				window.addEventListener('scroll', this.onScrollHandle);
				window.addEventListener('resize', this.onScrollHandle);
				window.addEventListener('orientationchange', this.onScrollHandle);


				this.onScrollHandle();
			}

		},

		beforeDestroy() {
			if (process.client) {
				window.removeEventListener('scroll', this.onScrollHandle);
				window.removeEventListener('resize', this.onScrollHandle);
				window.removeEventListener('orientationchange', this.onScrollHandle);
			}
		},

		methods: {
			async selectVariant(variant_s_i) {
				if (!this.curQuestion.is_multiselect) {
					this.current_selection = variant_s_i;
					// this.chooseAnswer() // Disable auto-next feature
				} else {
					const index = this.current_selection.indexOf(variant_s_i);
					if (index >= 0) {
						this.current_selection.splice(index, 1);
					} else {
						this.current_selection.push(variant_s_i);
					}
				}
				let releaseMutex = await this.mutex.acquire();
				await (this.$store.dispatch('questionnaire/updateAnswer', [this.$store.state.questionnaire.question.key, this.current_selection]));
				this.is_current_selection_saved = true;
				releaseMutex();

				this.chooseAnswer();
			},

			async goBack() {
				await this.goToURL({
					name: '-questionnaire-answersListKey-step-stepSn',
					params: {
						answersListKey: this.$store.state.questionnaire.answers_list.key,
						stepSn: this.$store.state.site.questionnaire_step_sn - 1,
						query: this.$route.query
					},
					query: this.$route.query
				});
			},

			resetState() {
				this.$refs['step-container-inner'].scrollTo && this.$refs['step-container-inner'].scrollTo({
					top: 0,
					behavior: 'smooth'
				});
			},
			async chooseAnswer() {
				if (!this.isGoodSelection) {
					return;
				}
				let releaseMutex = await this.mutex.acquire();
				if (!this.is_current_selection_saved) {
					await Promise.all([
						this.$store.dispatch('questionnaire/updateAnswer', [this.$store.state.questionnaire.question.key, this.current_selection])
					]);
					this.is_current_selection_saved = true;
				}
				releaseMutex();

				(Array.isArray(this.current_selection) ? this.current_selection : [this.current_selection])
						.map(answer_sn => {
							this.postAnalyticsMessageToOrigin(
									'quiz_answer_chosen',
									this.$store.state.questionnaire && this.$store.state.questionnaire.question && this.$store.state.questionnaire.question.key
									+ ': ' +
									this.$store.state.questionnaire.question.answer_variants[answer_sn].title
							);
						});
				if (Array.isArray(this.current_selection) && this.current_selection.length === 0) {
					this.postAnalyticsMessageToOrigin(
							'quiz_answer_chosen',
							this.$store.state.questionnaire && this.$store.state.questionnaire.question && this.$store.state.questionnaire.question.key
							+ ': Nothing selected'
					);
				}
				;
				// this.postAnalyticsMessageToOrigin(
				// 	'quiz_answer_chosen'
				// 	// {
				// 	// 	name: 'quiz_answers_chosen',
				// 	// 	technology: this.$store.state.site.technology.key,
				// 	// 	question: {
				// 	// 		sn: this.$store.state.site.questionnaire_step_sn,
				// 	// 		key: this.$store.state.questionnaire && this.$store.state.questionnaire.question && this.$store.state.questionnaire.question.key,
				// 	// 		title: this.$store.state.questionnaire && this.$store.state.questionnaire.question && this.$store.state.questionnaire.question.question
				// 	// 	},
				// 	// 	answers: (Array.isArray(this.current_selection) ? this.current_selection : [this.current_selection])
				// 	// 		.map(answer_sn => {
				// 	// 			return {
				// 	// 				sn: answer_sn,
				// 	// 				value: this.$store.state.questionnaire.question.answer_variants[answer_sn].value,
				// 	// 				title: this.$store.state.questionnaire.question.answer_variants[answer_sn].title
				// 	// 			}
				// 	// 		})
				// 	// }
				// );

				if (this.$store.state.questionnaire.answers_list.questions.length > this.$store.state.site.questionnaire_step_sn + 1) {
					this.goToURL({
						name: '-questionnaire-answersListKey-step-stepSn',
						params: {
							answersListKey: this.$store.state.questionnaire.answers_list.key,
							stepSn: this.$store.state.site.questionnaire_step_sn + 1
						},
						query: this.$route.query
					});
				} else {
					this.postAnalyticsMessageToOrigin('quiz_finished', 'Quiz Finished');
					this.goToURL({
						name: '-questionnaire-answersListKey-report',
						params: {
							answersListKey: this.$store.state.questionnaire.answers_list.key,
						},
						query: this.$route.query
					});
				}
			},

			onScroll() {
				if (this.$refs.navbar) {
					this.is_navbar_fixed = this.$refs.navbar.getBoundingClientRect().bottom > window.innerHeight;
				}
			},

			numeral
		},
		head() {
			let ret = {
				title: this.$store.state.site.article.head.title + ' - Step #' + (this.$store.state.site.questionnaire_step_sn + 1).toFixed(0),
				meta: [
					{
						name: 'description',
						content: this.$store.state.site.article.head.meta_description + ' - Step #' + (this.$store.state.site.questionnaire_step_sn + 1).toFixed(0)
					},
					{
						name: 'robots',
						content: 'noindex'
					}
				],
			};
			return ret;
		},
	}
</script>

<style lang="scss" type="text/scss">
	@import "~/assets/styles/tt-readiness/vuebar.scss";
</style>

<style lang="scss" type="text/scss" module="m">
	@import "~/assets/styles/tt-readiness/defs.scss";

	.header {
		position: fixed;
		z-index: 100;
		background: $clr-white;
		left: 0;
		right: 0;
		top: 0;
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.1);

		&__container {
			@include container-lg();
			display: flex;
			flex-direction: row;
			align-items: center;
			padding-top: 24px;
			padding-bottom: 24px;
		}

		&__logo_m {
			height: 30px;

			@include media-min('sm') {
				display: none;
			}
		}

		&__logo {
			display: none;
			height: 30px;

			@include media-min('sm') {
				display: inline-block;
			}
		}

		&__sep {
			margin: 0 10px;
			width: 1px;
			align-self: stretch;
			background-color: $clr-gray-darky;
		}

		&__descriptor {
			color: $clr-gray-blue;

			@include media-min('xs') {
				font-size: $font-size-18;
			}

			@include media-min('sm') {
				font-size: $font-size-20;
			}
		}

	}

	.frame {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;

		@include media-min-specific(1160px) {
			@include media-h-min('xs') {
				.logos_line {
					position: fixed;
					bottom: 0;
					left: 0;
					right: 0;
				}
			}
		}

		&__container {
			padding: 78px 0 92px;
			display: flex;
			flex-direction: column;
			align-items: center;
			min-height: 100vh;
			justify-content: space-around;
			@include container();

			@include media-min('sm') {
				padding-bottom: 0;
			}
		}

		&__logo {
			height: 40px;
		}

		h1 {
			margin: 15px 0 0;
			color: $clr-blue;
			font-size: $font-size-16;
			font-weight: normal;
		}
	}

	.step {
		height: 100%;
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		padding: 32px 0;
		width: 100%;

		@include media-min('sm') {
			@include media-h-min-specific(800px) {
				min-height: 424px;
			}
			@include media-h-min-specific(900px) {
				min-height: 470px;
			}
		}

		&__question {
			flex-grow: 1;
			margin-bottom: 60px;
			font-weight: 400;
			text-align: left;
			width: 100%;

			&_title {
				font-size: $font-size-24;
				color: $clr-gray-blue;
				text-align: center;

				@include media-min('sm') {
					font-size: $font-size-32;
				}

				b, strong {
					font-weight: 600;
					color: $clr-blue;
				}
			}

			&_desc {
				margin-top: 10px;
			}
		}

		&__variant {
			width: 100%;
			position: relative;
			cursor: pointer;
			display: flex;
			flex-direction: row;
			align-items: center;
			justify-content: flex-start;
			padding: $GRID_COL_SPACE $GRID_COL_SPACE/2;
			border-radius: 5px;
			border: 1px solid transparent;
			min-width: 300px;
			@include unselectable();

			@include media-min('sm') {
				flex: 1 1 33%;
				min-width: auto;
				padding: 43px 10px 36px;
				flex-direction: column;
				font-size: $font-size-18;
				margin-right: 10px;

				&:last-child {
					margin-right: 0;
				}
			}

			&:hover {
				background-color: $clr-blue-white;
				border-color: rgba($clr-blue, 0.25);
				box-shadow: 0 4px 8px rgba($clr-black, 0.12);
			}

			&_desc {
				color: $clr-gray-dark;
				margin-top: 5px;
				font-size: $font-size-12;
			}

			&_pic {
				width: 85px;
				height: 85px;
				background-position: center;
				background-size: contain;
				background-repeat: no-repeat;
				margin-right: 40px;
				flex-shrink: 0;

				@include media-min('sm') {
					margin-right: 0;
					margin-bottom: 27px;
				}
			}

			&_texts {
				display: flex;
				flex-direction: column;
				justify-content: center;
				align-items: flex-start;

				@include media-min('sm') {
					text-align: center;
				}
			}

			&___selected {
				border-color: rgba($clr-blue, 0.25);

			}
		}

		&__variants {
			flex-grow: 2;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;
			margin-left: -$GRID_COL_SPACE/2;
			margin-right: -$GRID_COL_SPACE/2;

			@include media-min('sm') {
				flex-direction: row;
				align-items: flex-start;
				width: 100%;
				flex-wrap: nowrap;
			}
		}
	}

	.nav {
		width: 100%;
		padding: 20px 0 32px;
		position: fixed;
		z-index: 100;
		left: 0;
		right: 0;
		bottom: 0;
		background: $clr-white;
		font-size: $font-size-20;
		color: $clr-gray-blue;

		@include media-min('sm') {
			position: static;
		}

		&__container {
			@include media-max('xs') {
				@include container-lg();
			}

			background: white;
			display: flex;
			flex-direction: row;
			justify-content: space-between;
			flex-wrap: wrap;
			align-items: center;

		}

		&__progress {
			position: relative;
			top: -20px;
			width: 100%;
			background: $clr-gray;
			height: 10px;
			border-radius: 5px;
			overflow: hidden;

			@include media-min('sm') {
				left: 0;
				right: 0;
				height: 5px;
				border-radius: 2px;
			}

			&_fill {
				position: absolute;
				left: 0;
				top: 0;
				bottom: 0;
				background: $clr-blue;
				min-width: 5px;
				transition: width $transition_speed_quick;
			}
		}

		&___fixed &__progress {
			left: 0;
			right: 0;
		}

		&__next {
			@include media-min('sm') {
				min-width: 130px;
			}
		}

		&__back {
			cursor: pointer;
			visibility: hidden;
			display: inline-flex;
			flex-direction: row;
			align-items: center;
			white-space: nowrap;

			&___visible {
				visibility: visible;
			}

			svg {
				width: 7px;
				height: 12px;
				margin-right: 8px;
				margin-top: -1px;
			}
		}

		&__side {
			width: 100px;

			@include media-min('sm') {
				width: 130px;
			}

			&:last-child {
				text-align: right;
			}
		}
	}
</style>
