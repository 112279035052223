<template>
	<transition name="fade">
		<div :class="m.frame">

			<div :class="m.frame__container">

				<a :class="m.frame__logo" href="https://toptal.com/?remote_quiz_id=">
					<LogoSvg :class="m.frame__logo"></LogoSvg>
				</a>
				<h1>Remote Readiness Assessment</h1>
				<div :class="m.report">
					<div :class="m.report__title">
						Would you like to receive your report by email?
					</div>


					<div class="form-container">
						<div class="form_box">
							<p v-if="!is_form_sent" class="form_box-title">
								Your personalized recommendations are ready.
							</p>
							<form v-if="!is_form_sent" :class="m.form" @submit.prevent="sendVisitorDataAndRedirectToReport">

								<div :class="m.form__field">
									<input v-model="form.full_name" name="first_name" placeholder="First Name">
								</div>

								<div :class="[m.form__field, is_email_wrong && m.form__field___wrong]">
									<input ref="email_input" v-model="form.email"
										   @keyup="is_email_checking && emailCheck()" name="email" placeholder="Work Email Address *"
										   type="email">
								</div>

								<div v-if="is_email_wrong" :class="m.form__wrong_msg">
									Please enter a valid email address
								</div>

								<button :class="[global_style.btn, global_style.btn___success]">Get Your Result</button>
								<a href="#" @click.prevent="redirectToReport" target="_parent" :class="m.form__skip">Skip this step</a>
							</form>

							<div v-else :class="m.form__thanks">
								Thanks for submitting!
							</div>
						</div>
					</div>

					<div :class="m.report__ps">
						By submitting this form, you are agreeing to Toptal’s
						<a href="https://www.toptal.com/company-tos" target="_parent">Terms of Service</a>, <a href="https://www.toptal.com/privacy" target="_parent">Privacy Policy</a>, and <a href="https://www.toptal.com/cookie-policy" target="_parent">Cookie Policy</a>.
					</div>
				</div>
			</div>
			<TrustLine></TrustLine>
		</div>
	</transition>
</template>

<script>
	import PfhOutsideLink from '~/components/pfh-outside-link.vue';
	import LogoSvg from '../svg/toptal_logo.svg';
	import ArrowSvg from '../svg/back_arrow.svg';
	import TrustLine from '../components/trust_line';

	import ConsultBlock from '../blocks/consult/pub';

	export default {
		components: {
			PfhOutsideLink,
			ConsultBlock,
			LogoSvg,
			ArrowSvg,
			TrustLine,
		},

		props: {
			global_style: {
				required: true
			}
		},

		head() {
			let ret = {
				title: 'Remote Readiness',
				meta: [
					{
						name: 'description',
						content: ''
					},
					{
						name: 'robots',
						content: 'noindex'
					}
				],
			};
			return ret;
		},

		data() {
			return {
				isReportShown: false,
				report: null,
				is_agreement_icon_shown: true,
				is_email_wrong: false,
				is_email_checking: false,
				is_form_sent: false,
				form: {
					full_name: '',
					email: ''
				}
			}
		},

		computed: {
			is_extended_for_staff() {
				return this.$route.query.extended_for_staff;
			},

			curYear() {
				return (new Date).getFullYear();
			},

			form_url() {
				return `${this.$route.query.origin}/hire/remote?remote_quiz_id=${this.$store.state.questionnaire.answers_list.key}`
			}
		},

		async created() {
			this.$root.$children[1].is_fullscreen = true;
			if (process.client) {
				await this.$store.state.graph.session_init_promise;
			}

			let report_info = await this.MODELS.AnswersList(this.$store.state.questionnaire.answers_list).get_report_info();
			this.report = report_info.report;
		},

		methods: {
			async goTo(step_sn) {
				return this.goToURL({
					name: '-questionnaire-answersListKey-step-stepSn',
					params: {
						answersListKey: this.$store.state.questionnaire.answers_list.key,
						stepSn: step_sn
					}
				});
			},

			// createScriptTag(id, src) {
			// 	return new Promise((resolve) => {
			// 		let scriptEl = document.getElementById(id);
			//
			// 		if (!scriptEl) {
			// 			scriptEl = document.createElement('script');
			// 			scriptEl.setAttribute('id', id);
			// 			document.body.appendChild(scriptEl);
			// 		}
			//
			// 		if (src.substr(0, 4) === 'http') {
			// 			scriptEl.setAttribute('src', src);
			// 			scriptEl.setAttribute('defer', false);
			// 			scriptEl.addEventListener('load', resolve);
			// 			scriptEl.async = false;
			// 		}
			// 		else {
			// 			scriptEl.innerHTML = src;
			// 			resolve();
			// 		}
			// 	})
			// },

			emailCheck() {
				this.is_email_wrong = !/^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/g.test(this.form.email);
				if (this.is_email_wrong) {
					this.is_email_checking = true;
				}

				return !this.is_email_wrong;
			},
			async redirectToReport() {
				let redirect_to = `${this.report.redirect_to}?qid=${this.$store.state.questionnaire.answers_list.key}`;
				if (this.$route.query.origin) {
					this.postMessageToOrigin({kind: 'redirect', url: redirect_to})
				} else {
					window.location = redirect_to;
				}
			},
			async sendVisitorDataAndRedirectToReport() {
				if (!this.emailCheck()) return false;
				await this.MODELS.User(this.$store.state.user).send_visitor_info(
						JSON.parse(JSON.stringify(this.form))
				);
				if (!this.report || !this.report.redirect_to) {
					alert('Bad report gotten!');
					return false;
				}
				this.redirectToReport();
			}
		},

		async mounted() {
		},

		beforeDestroy() {
		}
	}
</script>

<style>
	.form-container {
		width: 100%;
		display: -webkit-box;
		display: -ms-flexbox;
		display: flex;
		-ms-flex-wrap: nowrap;
		flex-wrap: nowrap;
		-webkit-box-align: center;
		-ms-flex-align: center;
		align-items: center;
		-webkit-box-pack: center;
		-ms-flex-pack: center;
		justify-content: center;
	}

	.form_box {
		z-index: 2;
		border-radius: 4px;
		padding: 38px 16px 32px;
		-webkit-box-sizing: border-box;
		box-sizing: border-box;
		background-color: white;
		-webkit-box-shadow: 0 4px 35px 0 rgba(38, 45, 61, 0.2);
		box-shadow: 0 4px 35px 0 rgba(38, 45, 61, 0.2);
	}

	.form_box.has-bottom_margin {
		margin-bottom: 40px;
	}

	.form_box-title {
		margin: 0;
		color: black;
		font-size: 16px;
		font-weight: 100;
		line-height: 20px;
		text-align: center;
		margin-bottom: 25px;
	}

	@media only screen and (min-width: 768px) {
		.form_box {
			width: 434px;
			padding: 37px 32px 32px;
		}
	}

	@media only screen and (min-width: 1024px) {
		.form_box {
			padding: 36px 32px;
		}
	}

	@media only screen and (max-width: 767px) {
		.form_box {
			margin-left: 16px;
			margin-right: 16px;
		}

		.form_box-title {
			font-size: 15px;
		}
	}

	.form-title {
		text-align: left;
	}

</style>

<style lang="scss" type="text/scss" module="m">
	@import "~/assets/styles/tt-readiness/defs.scss";

	.form {
		display: flex;
		flex-direction: column;
		align-items: stretch;

		* {
			margin-bottom: 16px;

			&:last-child {
				margin-bottom: 0;
			}
		}

		&__thanks {
			text-align: center;
			margin: 40px 0;
			font-size: $font-size-28;
		}

		&__field {
			position: relative;

			&::after {
				content: 'Private';
				position: absolute;
				right: 16px;
				padding-left: 20px;
				top: 0;
				bottom: 0;
				font-size: $font-size-13;
				color: $clr-gray-dark;
				font-weight: 200;
				display: flex;
				flex-direction: row;
				align-items: center;
				background-image: url(/tt-readiness/svg/icon_private.svg);
				background-position: 0 47%;
				background-repeat: no-repeat;
			}

			input {
				width: 100%;
				height: 48px;
				padding: 0 85px 0 10px;
				line-height: 48px;
				border: 1px solid $clr-gray-dark-light;
				font-size: $font-size-13;
				outline: none;

				&:focus {
					border-color: $clr-blue;
				}
			}

			&___wrong input {
				border-color: $clr-red;
			}
		}

		&__wrong_msg {
			color: $clr-red;
			font-size: $font-size-14;
			margin-top: -5px;
		}

		&__skip {
			font-size: $font-size-14;
			align-self: center;
			color: $clr-gray-dark;
			text-decoration: none;
			transition: $transition_speed_quick;

			&:hover {
				color: $clr-black;
			}
		}

		button {
			width: 100%;
			font-size: 15px;
		}
	}

	.frame {
		min-height: 100vh;
		display: flex;
		flex-direction: column;
		justify-content: space-between;
		background: $clr-gray-white;

		&__container {
			padding: 50px 0 28px;
			display: flex;
			flex-direction: column;
			align-items: center;
			@include container();

			@include media-min('sm') {
				padding-bottom: 0;
			}

			@include media-min('md') {
				@include media-h-min-specific(900px) {
					margin-top: calc(10vh - 90px);
				}
				@include media-h-min-specific(1000px) {
					margin-top: calc(35vh - 340px);
				}
			}
		}

		&__logo {
			height: 40px;
		}

		h1 {
			margin: 15px 0 0;
			color: $clr-blue;
			font-size: $font-size-16;
			font-weight: normal;
		}
	}

	.report {
		display: flex;
		flex-direction: column;
		justify-content: space-around;
		align-items: center;
		margin-top: 24px;
		border-top: 1px solid $clr-gray;
		padding: 16px 0 32px;
		width: 100%;

		@include media-min('sm') {
			@include media-h-min-specific(800px) {
				min-height: 424px;
			}
			@include media-h-min-specific(900px) {
				min-height: 470px;
			}
		}

		&__title {
			flex-grow: 1;
			margin-bottom: 24px;
			font-weight: 400;
			font-size: $font-size-28;
			text-align: center;

			@include media-min('sm') {
				text-align: left;
			}
		}

		&__variant {
			width: 100%;
			margin-bottom: 25px;
			position: relative;
			padding-left: 26px;
			margin-left: 15px;
			padding-right: 15px;
			cursor: pointer;
			@include unselectable();

			@include media-min('xs') {
				margin-left: 30px;
				width: calc(100% - 30px);
			}

			@include media-min('sm') {
				margin-left: 0;
				padding-right: 0;
			}

			&_desc {
				color: $clr-gray-dark;
				margin-top: 5px;
				font-size: $font-size-12;
			}

			&::before {
				content: '';
				width: 16px;
				height: 16px;
				border: 1px solid $clr-blue;
				border-radius: 50%;
				position: absolute;
				left: 0;
				top: 2px;
			}

			&___selected::after {
				content: '';
				width: 8px;
				height: 8px;
				background: $clr-blue;
				border-radius: 50%;
				position: absolute;
				left: 5px;
				top: 7px;
			}

			&:hover {
				color: $clr-black;

				&::before {
					background: rgba($clr-black, .03);
				}
			}

			&___checkbox {
				&::before,
				&::after {
					border-radius: 0;
				}

				&::after {
					background: none;
				}
			}

			&___checkbox#{&}___selected {
				&::before {
					background: $clr-blue url(/tt-readiness/svg/check.svg);
					background-repeat: no-repeat;
					background-position: center;
				}

				&::after {
				}

				&::before:hover {
					background: $clr-blue-dark;
				}
			}
		}

		&__variants {
			flex-grow: 2;
			width: 100%;
			display: flex;
			flex-direction: column;
			flex-wrap: wrap;

			&___two_cols {
				@include media-min('sm') {
					column-count: 2;
					column-gap: 30px;
					display: block;
				}

				> * {
					@include media-min('sm') {
						padding-left: 26px;
						display: flex;
						flex-direction: column;
						-webkit-column-break-inside: avoid; /* Chrome, Safari, Opera */
						page-break-inside: avoid; /* Firefox */
						break-inside: avoid;
					}
				}
			}
		}

		&__ps {
			font-size: $font-size-14;
			color: $clr-black;
			text-align: center;
			margin-top: 32px;
			max-width: 490px;
		}
	}
	.step-wrapper {

	}
</style>
