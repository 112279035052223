exports = module.exports = require("../../../node_modules/css-loader/lib/css-base.js")(false);
// imports


// module
exports.push([module.id, ".logos_line_2Sia{padding:28px 0 0;background:#f6f8fb;width:100%}.logos_line__container_JwJp{left:inherit;margin-left:auto;margin-right:auto;position:static;width:calc(100vw - 40px);max-width:1170px;display:flex;flex-direction:row;justify-content:center;align-items:center;flex-wrap:wrap}.logos_line__logo_Ufz-{flex-shrink:0;margin:0 25px 22px;position:relative}.logos_line__logo_label_1WDG{font-size:9px;line-height:1.2em;color:#84888e;position:absolute;top:calc(100% - 2px)}.logos_line__intro_okFi{margin:0 25px 22px;font-size:12px;letter-spacing:.1em;text-transform:uppercase;color:#455065}@media (max-width:1140px){.logos_line__intro_okFi{width:100%;text-align:center}.logos_line__intro_okFi br{display:none}}", ""]);

// exports
exports.locals = {
	"logos_line": "logos_line_2Sia",
	"logos_line__container": "logos_line__container_JwJp",
	"logos_line__logo": "logos_line__logo_Ufz-",
	"logos_line__logo_label": "logos_line__logo_label_1WDG",
	"logos_line__intro": "logos_line__intro_okFi"
};